import React from 'react';
import './links.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'

function DevLinks(){
  return (
    <div style={{marginLeft:"4%", display:"flex", width:"150px", justifyContent:"space-around", alignSelf:"flex-start", padding:"30px 20px 0px"}}>
      <a href="https://www.linkedin.com/in/arvin-wallace-216621142" target="blank"><FontAwesomeIcon icon={faLinkedinIn} color="#85deff" size="2x"/></a>
      <a href="https://github.com/arvinwallace" target="blank"><FontAwesomeIcon color="#85deff"  icon={faGithub} size="2x"/></a>
    </div>
  )
}

export default DevLinks;

