import React from 'react';
import {ReactComponent as Loader} from './loader.svg';
import './password.css';

export default function Loading(){
  return(
    <div className="loader-container">
      <p className="validating-text">Checking for compromised password</p>
      <Loader className="svg-loader"/>
    </div>
  )
}