import React from 'react' 
import SignIn from './password/password-one.js';
import ColorSlider from './color-slider';
import BarChart from './bar-chart';
import NuggetSVG from './nugget';
import CodeLink from '../links/code-link';

function Demos(){
  return(
    <div style={{width:'90%', maxWidth:'700px', display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center"}}>
      <div style={{fontSize:"1.5rem", lineHeight:"1.5rem", alignSelf:"flex-start", width:"100%"}}>
          <p style={{marginBottom:"0"}}>HSL Color Sliders</p>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"5px", marginTop:"5px"}}>
              <p style={{marginBottom:"5px", marginTop:"5px", fontSize:"1rem", lineHeight:"1rem", color:"hsl(0, 0%, 80%)"}}>React UI</p>
              <CodeLink link="https://github.com/arvinwallace/HSA-color-slider"/>
          </div>
      </div>
      <div style={{borderBottom:"5px solid hsl(202, 100%, 48%)", width:"100%"}}></div>
      
      <div style={{maxWidth:"450px", width: "90%", marginBottom:"60px"}}>
        <ColorSlider/>
      </div>
      <div style={{fontSize:"1.5rem", lineHeight:"1.5rem", alignSelf:"flex-start", width:"100%"}}>
          <p style={{marginBottom:"0"}}>Live Validation Feedback</p>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"5px", marginTop:"5px"}}>
            <p style={{fontSize:"1rem", margin:"0px", lineHeight:"1rem", color:"hsl(0, 0%, 80%)"}}>React Hooks</p>
            <CodeLink link="https://github.com/arvinwallace/live-validations"/>
          </div>
      </div>
      <div style={{borderBottom:"5px solid hsl(202, 100%, 48%)", width:"100%"}}></div>
      
      <div style={{maxWidth:"450px", width: "90%", marginBottom:"60px"}}>
        <SignIn/>
      </div>
      <div style={{fontSize:"1.5rem", lineHeight:"1.5rem", alignSelf:"flex-start", width:"100%"}}>
          <p style={{marginBottom:"0"}}>React Bar Charts</p>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"5px", marginTop:"5px"}}>
            <p style={{fontSize:"1rem", margin:"0px", lineHeight:"1rem", color:"hsl(0, 0%, 80%)"}}>d3 + React</p>
            <CodeLink link="https://github.com/arvinwallace/d3-react-barchart"/>
          </div>
      </div>
      <div style={{borderBottom:"5px solid hsl(202, 100%, 48%)", width:"100%"}}></div>
      
      <div style={{maxWidth:"650px", width: "90%", marginBottom:"60px", marginTop:"40px"}}>
        <BarChart/>
      </div>
      <div style={{fontSize:"1.5rem", lineHeight:"1.5rem", alignSelf:"flex-start", width:"100%"}}>
          <p style={{marginBottom:"0"}}>SVG Animations</p>
          <div style={{display:"flex", justifyContent:"space-between", width:"100%", marginBottom:"5px", marginTop:"5px"}}>
            <p style={{fontSize:"1rem", margin:"0px", lineHeight:"1rem", color:"hsl(0, 0%, 80%)"}}>GSAP + React</p>
            <CodeLink link="https://github.com/arvinwallace/nugget-animation"/>
          </div>
      </div>
      <div style={{borderBottom:"5px solid hsl(202, 100%, 48%)", width:"100%"}}></div>
      
      <div style={{maxWidth:"650px", width: "90%", marginBottom:"90px", marginTop:"40px"}}>
        <NuggetSVG/>
      </div>
    </div>  
  )
}

export default Demos;