
import About from './about'
import Hero from './hero';
import Demo from './demos/demos'
import DevLinks from './links/dev-links';
import ProfilePic from './pics/handsome.png';
import FingerPic from './pics/fingermouth.png';
import './App.css';

function App() {
  const createBubbles = () => {
    const ray = [1,2,3,4,5,6,7];
    return ray.map(item => {
      let shape = Math.ceil(Math.random() * 15)+'px'
      let toppy = Math.ceil(Math.random() * 250 + 150)
      let lefty = Math.ceil(Math.random() * 900 + 150)
      console.log(shape)
      return (
        <div style={{
          width: `${shape}`,
          height: `${shape}`,
          position:"absolute",
          backgroundColor:"#2cc1f8",
          borderRadius:"50%",
          top:`${toppy}px`,
          left:`${lefty}px`,
          transform:'translate(20%, 200px)'
        }}></div>
      )
    })
  }
  return (
    <div className="App-header">
      <DevLinks/>
      {createBubbles()}
      {/* <Hero pic={ProfilePic} text="Arvin Wallace"/>
      <About/> */}
      <Hero pic={FingerPic} text="Latest Projects"/>
      <Demo/>
    </div>
  );
}

export default App;
