import React from 'react';
import './links.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

function CodeLink({link}){
  return (
    <div style={{display:"flex", alignSelf:"flex-end"}}>
      <a href={link} target="blank" style={{lineHeight:"1rem", color:"rgb(204, 204, 204)", display:"flex", fontSize:"1rem"}}>
        <FontAwesomeIcon color="#85deff"  icon={faGithub}/>
        <span style={{marginLeft:"5px"}}>View source</span>
      </a>
    </div>
  )
}

export default CodeLink;