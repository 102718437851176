import React from 'react'  
import './hero.css'

function Hero({pic,text}){
  return (
    <div className="hero-container">
      <div className="profile-pic-wrapper">
        <img className="profile-pic" src={pic} alt={text}></img>
      </div>
      <p className="my-name">{text}</p>
    </div>
  )
}

export default Hero;